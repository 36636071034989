// todo: cleanup
const TEXT_RULES = {
  thumb: {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: false,
    thumb: false,
  },
};

if (__ALL__ || __WSJ__) {
  TEXT_RULES['4u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['8u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['12u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['16u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES.appfeed = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,
    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.homepage = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,
    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.baby_homepage = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,
    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.soc = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,

    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.ipadFeed2305 = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,

    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.video = {
    title: true,
    dek: true,
    subdek: false,
    note: false,
    source: true,
    thumb: false,
  };
  TEXT_RULES.verticalVideo = {
    title: true,
    dek: true,
    subdek: false,
    note: false,
    source: true,
    thumb: false,
  };
  TEXT_RULES.twitterVideo = {
    title: true,
    dek: false,
    subdek: false,
    note: true,
    source: true,

    thumb: false,
  };
}

if (__ALL__ || __DJRISKJOURNAL__) {
  TEXT_RULES['4u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['8u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['12u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['16u'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES.appfeed = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,
    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.homepage = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,
    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.baby_homepage = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,
    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.soc = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,

    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.ipadFeed2305 = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: true,

    thumb: false,
    subdekPromo: true,
    sourcePromo: true,
    notePromo: true,
  };
  TEXT_RULES.video = {
    title: true,
    dek: true,
    subdek: false,
    note: false,
    source: true,
    thumb: false,
  };
  TEXT_RULES.verticalVideo = {
    title: true,
    dek: true,
    subdek: false,
    note: false,
    source: true,
    thumb: false,
  };
  TEXT_RULES.twitterVideo = {
    title: true,
    dek: false,
    subdek: false,
    note: true,
    source: true,

    thumb: false,
  };
}

if (__ALL__ || __BARRONS__) {
  TEXT_RULES['barrons-4col-image'] = {
    title: true,
    dek: true,
    subdek: true,
    note: false,
    source: false,
    date: false,

    thumb: false,
  };
  TEXT_RULES['barrons-8col-image'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,

    thumb: false,
  };
  (TEXT_RULES['barrons-10col-image'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  }),
    (TEXT_RULES['barrons-fullscreen-image'] = {
      title: true,
      dek: true,
      subdek: true,
      note: true,
      source: true,
      date: true,
      thumb: false,
    });
  TEXT_RULES.b4u = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['barrons-8col-inset'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  (TEXT_RULES['barrons-12col-inset'] = {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  }),
    (TEXT_RULES['barrons-16col-inset'] = {
      title: true,
      dek: true,
      subdek: true,
      note: true,
      source: true,
      date: true,
      thumb: false,
    }),
    (TEXT_RULES['barrons-fullscreen-inset'] = {
      title: true,
      dek: true,
      subdek: true,
      note: true,
      source: true,
      date: true,
      thumb: false,
    }),
    (TEXT_RULES['print-barrons'] = {
      title: true,
      dek: true,
      subdek: true,
      note: true,
      source: true,
      date: true,
      thumb: false,
    });
}

if (__ALL__ || __FNLONDON__) {
  TEXT_RULES.fn4u = {
    title: true,
    dek: false,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES.fn8u = {
    title: true,
    dek: false,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES.fn12u = {
    title: true,
    dek: false,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES.fn16u = {
    title: true,
    dek: false,
    subdek: true,
    note: true,
    source: true,
    date: true,
    thumb: false,
  };
  TEXT_RULES['print-fn'] = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: false,
    thumb: false,
  };
  TEXT_RULES['thumb-fn'] = {
    title: false,
    dek: false,
    subdek: false,
    note: false,
    source: false,
    date: false,
    thumb: true,
  };
}

if (__MARKETWATCH__) {
  TEXT_RULES['marketwatch-ZT'] = {
    title: false,
    dek: false,
    subdek: false,
    legend: false,
    note: false,
    source: false,
    date: false,
  };
  TEXT_RULES['marketwatch-ZR'] = {
    title: false,
    dek: false,
    subdek: false,
    legend: false,
    note: false,
    source: false,
    date: false,
  };
}
TEXT_RULES.k4u = {
  title: true,
  dek: false,
  subdek: true,
  note: true,
  source: true,
  date: true,
  thumb: false,
};

export function getBestRule(id) {
  // If a rule for @param id is found, return it.
  if (TEXT_RULES[id] !== undefined) {
    return TEXT_RULES[id];
  }
  // If a rules that begins with @param id is found, return it. This is useful
  // for example with any id that starts with "thumb".
  for (let i = 0, keys = Object.keys(TEXT_RULES); i < keys.length; i++) {
    const key = keys[i];
    if (id.indexOf(key) === 0) {
      return TEXT_RULES[key];
    }
  }
  // Default:
  return {
    title: true,
    dek: true,
    subdek: true,
    note: true,
    source: true,
    date: true,
  };
}

export function isCompactThumb(options) {
  return (options.product === 'marketwatch' || __ALL__ || __MARKETWATCH__) && options.height < 170;
}

export default TEXT_RULES;
