import WebFont from 'webfontloader';

/**
 * Preload fonts depending on product. If fonts are *not* preloaded, then
 * line breaks fail (because line breaks are determined with a fallback font).
 */
export function fonts(options, complete) {
  const { product, print } = options;
  const info = fontsInfo(product, print);
  let interval = 0;
  if (info === true) {
    complete();
  } else {
    const status = (window.__charts_fonts_status__ =
      typeof window.__charts_fonts_status__ === 'object' ? window.__charts_fonts_status__ : {});

    const callbackComplete = () => {
      status[info.product] = 'complete';
      complete();
    };

    const checkComplete = () => {
      if (status[info.product] === 'complete') {
        clearInterval(interval);
        complete();
      }
    };

    const checkQueued = () => {
      if (!areFontsLoading()) {
        clearInterval(interval);
        if (status[info.product] === 'complete') {
          complete();
        } else {
          status[info.product] = 'loading';
          WebFont.load({
            custom: info,
            active: callbackComplete,
            inactive: callbackComplete,
          });
        }
      }
    };

    if (status[info.product] === 'loading') {
      interval = setInterval(checkComplete, 1);
    } else if (status[info.product] === 'complete') {
      complete();
    } else {
      interval = setInterval(checkQueued, 1);
    }
  }
}

function areFontsLoading() {
  const status = window.__charts_fonts_status__;
  if (status) {
    const keys = Object.keys(status);
    for (let i = 0; i < keys.length; i++) {
      if (status[keys[i]] === 'loading') {
        return true;
      }
    }
  }
  return false;
}

function fontsInfo(product, print = false) {
  if (__ALL__ || __WSJ__) {
    if (product === 'wsj') {
      return {
        product,
        families: ['Retina', 'Retina Narrow', 'Noto Sans JP'],
        urls: [
          'https://asset.wsj.net/dynamic-insets/charts/wsj-digital.css',
          'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;700&display=swap',
        ],
      };
    }
  }
  if (__ALL__ || __DJRISKJOURNAL__) {
    if (product === 'djriskjournal') {
      return {
        product,
        families: ['Retina', 'Retina Narrow'],
        urls: ['https://asset.wsj.net/dynamic-insets/charts/wsj-digital.css'],
      };
    }
  }
  if (__ALL__ || __BARRONS__) {
    if (product === 'barrons') {
      return {
        product,
        families: ['Aileron'],
        urls: ['https://www.barrons.com/fonts/Aileron.css'],
      };
    }
  }
  if (__ALL__ || __FNLONDON__) {
    if (product === 'fnlondon') {
      return {
        product,
        families: ['Theinhardt:n3,n4,n6'],
        urls: ['https://s3.amazonaws.com/fonts.wsj.net/Theinhardt.css'],
      };
    }
  }
  if (__ALL__ || __MARKETWATCH__) {
    if (product === 'marketwatch') {
      return {
        product,
        families: ['Lato', 'Mulish'],
        urls: [
          'https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mulish:wght@400;800&display=swap',
        ],
      };
    }
  }

  return true;
}
