/* eslint-disable no-param-reassign */
import assign from 'lodash.assign';
import moment from 'moment-timezone';
import * as format from '../../data/format';
import { MONTHS, QUARTERLY_Q_LABEL_THRESHOLD, INTERVAL_OPTIONS } from '../../data/constants';
import { YEAR_MILLISECONDS } from './axes-common';
import { findMilliseconds } from '../annotations/index';
import { addZeroes } from '../../data/zero';
import { getHiddenCategories, getXAxisClassName } from './axes.utils';
import { setVerticalAnnotations } from '../annotations/verticalAnnotations.prerender';
import { hasVideoGroup, hasZT, hasThumb, hasPrint, hasWSJVideoGroup } from '../utils';
import {
  xAxisTitleOffset,
  yAxisOffset,
  yAxisTitleOffset,
  yAxisGridLineDashStyle,
  LINEAR,
  LOGARITHMIC,
} from './axes.constants';

const { YEAR } = INTERVAL_OPTIONS;

export const preRender = (data, options) => {
  data = writeCommonOptions(data, options);
  switch (options.type) {
    case 'pie':
      return data;
    case 'bar':
      data = writeHorizontalOptions(data, options);
      break;
    default:
      data = writeVerticalOptions(data, options);
      break;
  }

  data = writeMinMax(data, options);

  if (
    !options.yAxisMobileEnabled &&
    options.yAxisMobileInterval !== '' &&
    options.yAxisMobileInterval !== undefined &&
    options.width < 620
  ) {
    setTickIntervalForYAxis(options, data, true);
  }

  if (
    options.yAxisInterval !== '' &&
    options.yAxisInterval !== undefined &&
    (options.width >= 620 || options.yAxisMobileEnabled)
  ) {
    setTickIntervalForYAxis(options, data, false);
  }

  if (options.type === 'bar') {
    data.yAxis.plotLines[0].className = 'bar-yAxis-plotLine';
  }
  // vertical annotations
  data.yAxis.plotLines = setVerticalAnnotations(options);
  return data;
};

function setTickIntervalForYAxis(options, data, isMobile) {
  const typeOfInterval = isMobile ? 'yAxisMobileInterval' : 'yAxisInterval';
  const interval = options[typeOfInterval];
  if (interval !== '') {
    data.yAxis.tickInterval = Number(interval);
  }
}

function tickIntervalCalc(options, data, isMobile) {
  const typeOfInterval = isMobile ? 'mobileXAxisInterval' : 'xAxisInterval';
  const interval = options[typeOfInterval].split(' ')[0];
  const unit = options[typeOfInterval].split(' ')[1];
  if (unit === 'years' || unit === 'year') {
    data.xAxis.tickInterval = 24 * 3600 * 1000 * 365 * parseInt(interval);
  }
  if (unit === 'months' || unit === 'month') {
    data.xAxis.tickInterval = 24 * 3600 * 1000 * 30 * parseInt(interval);
  }
  if (unit === 'day') {
    data.xAxis.tickInterval = 24 * 3600 * 1000;
  }
  if (unit === 'week') {
    data.xAxis.tickInterval = 24 * 3600 * 1000 * 7;
  }
}

function checkForType(type) {
  return function (...types) {
    return types.includes(type);
  };
}

/**
 * Options common to all charts with axes.
 * Sets the following xAxis properties:
 * title - text, offset;
 * startOnTick;
 * endOnTick;
 * overscroll;
 * className.
 * Y-axis properties:
 * title - text, offset;
 * offset;
 * opposite;
 * gridLineDashStyle;
 * startOnTick;
 * endOnTick;
 * labels - empty object
 * plotLines - initial settings
 * allowDecimals
 * @see https://api.highcharts.com/highcharts/xAxis
 * @see https://api.highcharts.com/highcharts/yAxis
 */
function writeCommonOptions(data, options) {
  const { type: chartType, allowDecimals, connectEmptyDataPoints, product } = options;
  const checkChartTypes = checkForType(chartType);
  const isVideoGroup = hasVideoGroup(options.id);
  const isZT = hasZT(options.id);
  const isBarGroup = chartType === 'column' || chartType === 'bar';
  const isScatter = chartType === 'scatter';

  if (checkChartTypes('line', 'area')) {
    data.plotOptions.series.connectNulls = connectEmptyDataPoints !== 'none';
  }

  const [startOnTickX, endOnTickX] = isScatter
    ? [!options.axes.minXAxisValue, !options.axes.maxXAxisValue]
    : [false, false];
  const [startOnTickY, endOnTickY] = [
    !options.axes.minYAxisValue && !options.logScale,
    isScatter || !options.axes.maxYAxisValue,
  ];
  const yAxisGridZindex = chartType === 'area' && (product === 'wsj' || product === 'djriskjournal') ? 4 : 1;
  const xAxisClassName = getXAxisClassName(isScatter, hasThumb(options.id));

  // x-axis title is not supported for MW ZT crop
  data.xAxis = {
    title: {
      text: isZT ? undefined : options.headings.xaxisTitle.text.toUpperCase(),
      offset: xAxisTitleOffset(options.print, isVideoGroup),
    },
    startOnTick: startOnTickX,
    endOnTick: endOnTickX,
    overscroll: 0,
    className: xAxisClassName,
  };

  data.yAxis = {
    title: {
      text: options.headings.yaxisTitle.text.toUpperCase(),
      offset: yAxisTitleOffset(isVideoGroup),
    },
    offset: yAxisOffset(isVideoGroup),
    opposite: false,
    gridLineDashStyle: yAxisGridLineDashStyle,
    startOnTick: startOnTickY,
    endOnTick: endOnTickY,
    labels: {},
    plotLines: [{ value: 0, zIndex: isBarGroup ? 5 : 3 }],
    allowDecimals,
    gridZIndex: yAxisGridZindex,
  };

  return data;
}

/**
 * Creates an additional range on the right side of x-axis, to ensure right most x-axis tick is visible.
 * Applies to market/irregular dates charts and line charts with marked data points.
 * @param {ChartlosOptions} options
 * @param {Boolean} isVideoGroup
 * @return {Number} value set in milliseconds
 */
function calculateOverscroll(options, isVideoGroup) {
  const { series, irregularDates, markedDataPoints } = options;
  const multiplier = isVideoGroup ? 25 : 5;

  return irregularDates || markedDataPoints
    ? findMilliseconds(options, series[0].data) * multiplier
    : 0;
}

/**
 * Writes chart options for horizontal bars.
 * There are 3 horizontal bar types: 'normal', 'aboveBar' and 'noScaleAndLeft'.
 * Only 'normal' can be stacked.
 * @param {Object} data
 * @param {ChartlosOptions} options
 * @returns {Object} modified data object
 */
function writeHorizontalOptions(data, options) {
  data.xAxis.className = 'bar-xaxis';
  const isCategoryChart = Array.isArray(options.categories);
  // set xAxis min & max based on horizontal bar type
  if (isCategoryChart) {
    data.xAxis.categories = getCategoryLabels(options.categories[0]);
    data.xAxis.tickmarkPlacement = 'on';
    const max = data.xAxis.categories.length - 1;
    if (options.horizontalBarType === 'normal') {
      if (options.print) {
        // shifts yAxis labels down on print normal horiz charts
        data.yAxis.offset = 2;
        // min and max together with pointPlacement= 'on' control plotline to start and end on the bars.
        data.xAxis.min = options.series.length === 1 || options.stacking ? -0.32 : -0.27;
      } else {
        data.xAxis.min = -0.5;
      }
      const plotLineAdditionBottom = options.print ? 0.35 : 0.5;
      data.xAxis.max = max + plotLineAdditionBottom;
    } else if (options.horizontalBarType === 'aboveBar') {
      data.xAxis.min = -0.2;
      data.xAxis.max = max + (options.print ? 0.18 : 0.1);
    } else {
      // options.horizontalBarType === noScaleAndLeft
      data.xAxis.min = options.print ? -0.32 : -0.4;
      data.xAxis.max = max + (options.print ? 0.32 : 0.4);
    }
  }
  data.xAxis.tickLength = 0;
  data.xAxis.offset = -2;

  if (options.horizontalBarType === 'normal') {
    // TODO: make inline styles constant
    data.xAxis.labels = {
      // do not delete style below until confirmed
      style: {
        whiteSpace: 'wrap',
        textOverflow: 'visible',
        lineHeight: options.styles.lineHeight,
      },
      step: 1,
      x: -7,
      align: 'left',
      reserveSpace: true,
      formatter() {
        return uppercaseLabelFormatter(this.value, hasWSJVideoGroup(options));
      },
    };
    if (options.print) data.xAxis.labels.y = 3;
    if (options.axes.shown) {
      data.yAxis.reversedStacks = false;
      data.yAxis.opposite = !options.print;
      data.yAxis.reversed = !!options.axes.reversed;
      data.yAxis.labels = {
        y: options.print ? 5 : -5,
        style: { whiteSpace: 'nowrap' },
        overflow: 'allow',
        autoRotation: false,
        formatter() {
          const special =
            (options.axes.reversed && this.isLast) || (!options.axes.reversed && this.isFirst);
          return formatterBarYAxisLabel(
            options.axes.units,
            this.value,
            special,
            hasWSJVideoGroup(options)
          );
        },
      };
    }
  } else {
    // noScaleAndLeft & aboveBar
    // again, I think this needed for custom computations that happen onload.
    data.yAxis.visible = !!(options.print && options.horizontalBarType === 'noScaleAndLeft');
    if (options.axes.shown) {
      data.yAxis.labels = {
        autoRotation: false,
        formatter() {
          const special =
            (options.axes.reversed && this.isLast) || (!options.axes.reversed && this.isFirst);
          return formatterBarYAxisLabel(
            options.axes.units,
            this.value,
            special,
            hasWSJVideoGroup(options)
          );
        },
        /**
         * for 'noScaleAndLeft' case `enabled` get set to false on load.
         * I think this is required for custom calcutions of gridlines distance, but not sure
         */
        enabled: options.print && options.horizontalBarType === 'noScaleAndLeft',
      };
    }
    if (options.horizontalBarType === 'noScaleAndLeft') {
      data.yAxis.className = 'bar-yaxis';
      data.xAxis.labels = {
        style: {
          whiteSpace: 'wrap',
          textOverflow: 'visible',
          lineHeight: options.styles.lineHeight,
        },
        x: -7,
        align: 'left',
        reserveSpace: true,
        formatter() {
          return uppercaseLabelFormatter(this.value, hasWSJVideoGroup(options));
        },
      };
      if (options.print) data.xAxis.labels.y = 3;
    } else {
      // todo: figure out the way how to calculate y properly for above bar charts
      data.xAxis.labels = {
        style: {
          whiteSpace: 'nowrap',
        },
        x: -2,
        align: 'left',
        formatter() {
          return uppercaseLabelFormatter(this.value, hasWSJVideoGroup(options));
        },
      };
    }
  }
  return data;
}

function formatterBarYAxisLabel(units, value, special, transformUppercase) {
  value = format.decimals(value, units);
  value = format.commas(value, units);

  const preformattedPrefix = units.prefix || '';
  const preformattedSuffix = units.suffix || '';

  const prefix =
    transformUppercase && typeof preformattedPrefix === 'string'
      ? preformattedPrefix.toUpperCase()
      : preformattedPrefix;
  const suffix =
    transformUppercase && typeof preformattedSuffix === 'string'
      ? preformattedSuffix.toUpperCase()
      : preformattedSuffix;
  if (prefix && value.charAt(0) === '-') {
    // If value is negative, put the negative sign *before* the prefix.
    value = `-${prefix}${value.substr(1)}`;
  } else {
    value = `${prefix}${value}`;
  }
  if (special && suffix) {
    value += suffix;
  }
  return value;
}

function uppercaseLabelFormatter(value, transformUppercase) {
  return transformUppercase && typeof value === 'string' ? value.toUpperCase() : value;
}

const getYAxisValue = (cropSize, isVideoGroup) => {
  if (isVideoGroup) return 0;
  switch (cropSize) {
    case 'print-wsj':
      return 1;
    default:
      return -10;
  }
};

const getXAxisLabelsY = (isPrint, isVideoGroup, fontSize) => {
  if (isPrint) return fontSize + 2;
  if (isVideoGroup) return fontSize + 9;
  return fontSize + 7;
};

// Writes chart options for column charts (i.e, vertical bars) and charts for
// which the axes are the same as for column charts (i.e., line charts).
/**
 * Writes chart options for vertical aligned charts -
 * every available type except horiz. bar
 * @param {Object} data - mutable data object
 * @param {ChartlosOptions} options - chartlos options
 * @returns {Object} modified data object
 */
function writeVerticalOptions(data, options) {
  if (options.type === 'column' && !options.categoriesAreDates)
    data.xAxis.className = 'column-xaxis';
  const isVideoGroup = hasVideoGroup(options.id);
  const isPrint = options.print;
  const isZT = hasZT(options.id);
  const Q = options.quarterlyResults && options.series[0].data.length < QUARTERLY_Q_LABEL_THRESHOLD;

  // this shift ticks down
  if (isPrint) data.xAxis.offset = 0.5;
  data.yAxis.tickPixelInterval = options.styles.lineHeight * 3;
  data.yAxis.reversed = !!options.axes.reversed;
  data.yAxis.type = options.logScale ? LOGARITHMIC : LINEAR;

  if (options.size === 'print-wsj') {
    data.xAxis.minorTickLength = 1;
  }

  data.yAxis.labels = {
    autoRotation: false,
    align: isVideoGroup ? 'left' : 'right',
    x: getYAxisValue(options.id, isVideoGroup),
    reserveSpace: isVideoGroup ? true : undefined,
    y: calculateYAxesLabelY(options, -5),
    formatter() {
      const tp = this.axis.tickPositions;
      const decimalsDelta = Math.abs(tp[0] - tp[1]);
      const decimalPlaces = Number.isInteger(decimalsDelta) ? 0 : roundPlaces(decimalsDelta);
      // special = should have prefix and/or suffix:
      const special =
        (options.axes.reversed && this.isFirst) || (!options.axes.reversed && this.isLast);
      const lengths = tp
        .map((label) => {
          const numArr = label.toString().split('.');
          if (numArr.length > 1) {
            return numArr[1].length;
          }
        })
        .filter((item) => item);
      const minDecimals = lengths.length && !options.logScale ? Math.max(...lengths) : 0;

      return formatterYAxisLabel(
        options.axes.units,
        this.value,
        decimalPlaces,
        special,
        minDecimals,
        hasWSJVideoGroup(options)
      );
    },
  };

  options.axes.tickStyle = options.axes.tickStyle || 'center';
  data.xAxis.labels = {
    enabled: !isZT,
    autoRotation: false,
    formatter() {
      return uppercaseLabelFormatter(this.value, hasWSJVideoGroup(options));
    },
  };
  data.xAxis.labels.align = 'center';
  data.xAxis.labels.y = getXAxisLabelsY(isPrint, isVideoGroup, options.styles.normalFontSize);
  const ignoreXTicks = (options.type === 'column' && !options.categoriesAreDates) || isZT;
  data.xAxis.tickLength = ignoreXTicks ? 0 : options.styles.tickLength;
  data.xAxis.labels.overflow = 'allow';

  if (options.type === 'scatter') {
    data.xAxis.type = 'linear';
    if (options.axes.minXAxisValue) data.xAxis.min = +options.axes.minXAxisValue;
    if (options.axes.maxXAxisValue) data.xAxis.max = +options.axes.maxXAxisValue;
    if (options.axes.xAxisIncrementsForScatter)
      data.xAxis.tickInterval = +options.axes.xAxisIncrementsForScatter;
  }

  if (
    Array.isArray(options.categories) &&
    !options.categoriesAreDates &&
    options.type !== 'scatter'
  ) {
    // data.xAxis.type = 'category';
    // if (options.type === 'scatter') {
    //   data.xAxis.min = 0.5;
    //   data.xAxis.max = options.categories[0].length - 1.5;
    // }
    const hiddenCategories = getHiddenCategories(options);
    const categoryLabels = getCategoryLabels(options.categories[0]);
    data.xAxis.categories = categoryLabels.map((label, i) =>
      hiddenCategories.includes(i) ? '' : label
    );
    data.xAxis.tickWidth = 1;
    data.xAxis.tickmarkPlacement = 'on';
    data.xAxis.tickPositioner = function () {
      return this.tickPositions.filter((position) => !hiddenCategories.includes(position));
    };
  }
  if (options.categoriesAreDates) {
    data.xAxis.labels.style = { whiteSpace: 'nowrap' };
    if (isVideoGroup) data.xAxis.labels.style.lineHeight = options.styles.normalFontSize;

    if (options.fiscalYears) {
      data.xAxis.tickInterval = YEAR_MILLISECONDS;
    } else {
      const firstDate = new Date(options.originalCategories[0]);
      const lastDate = new Date(options.originalCategories[options.originalCategories.length - 1]);
      const diff = Math.abs((firstDate.getTime() - lastDate.getTime()) / (24 * 3600 * 1000));
      const hasMoreThanTwoDays = diff > 2;
    }
    if (data.xAxis.tickInterval) {
      data.xAxis.tickInterval = Math.round(data.xAxis.tickInterval);
    }
    if (options.id === 'video') {
      data.xAxis.tickPixelInterval = 225;
    }

    // new x-axis interval code
    if (Q) {
      data.xAxis.labels.formatter = function () {
        const { label } = options.xAxisTicks[this.value];
        const parts = label.split(/\s/);
        const tmpQ = parts[0] || '';
        const tmpY = parts[1] || '';

        let formattedLabel = null;
        if (options.majorTicksArticle === YEAR && options.minorTicksArticle === YEAR) {
          formattedLabel = `${tmpQ} ${tmpY}`;
        } else {
          formattedLabel = `${tmpQ}${tmpY ? `<br>${tmpY}` : ''}`;
        }

        if (hasWSJVideoGroup(options)) {
          return formattedLabel?.toUpperCase() || null;
        }

        return formattedLabel || null;
      };
    } else {
      data.xAxis.labels.formatter = function () {
        const { label } = options.xAxisTicks[this.value];

        if (hasWSJVideoGroup(options)) {
          return label?.toUpperCase() || null;
        }

        return label || null;
      };
    }
    data.xAxis.tickPositioner = function () {
      return Object.keys(options.xAxisTicks)
        .map((value) => +value)
        .sort();
    };
    data.xAxis.type = 'datetime';
  }
  return data;
}

function calculateYAxesLabelY(options, initialValue) {
  if (options.id === 'print-barrons') return -2;
  const isVideoGroup =
    options.id === 'video' || options.id === 'twitterVideo' || options.id === 'verticalVideo';
  if (__WSJ__) {
    if (options.product === 'wsj') {
      if (options.size === 'print-wsj') {
        if (options.type === 'line') {
          return -1.5;
        }

        if (options.type === 'column') {
          return -1.5;
        }
      }
      if (options.type === 'scatter') {
        return 5;
      }
      if (isVideoGroup) {
        return -12;
      }
      return -3;
    }
  }

  if (options.product !== 'wsj' && options.type === 'scatter') {
    return 5;
  }

  return initialValue;
}

// Returns correct spacing between ticks on x-axis depending.
function getDatetimeTickInterval(context, options) {
  if (options.datesAll) {
    let currYear;
    let yearsTraversed = 0;
    let yearRepeated = false;

    let currMonth;
    let monthsTraversed = 0;
    let monthRepeated = false;

    let currDate;
    let datesTraversed = 0;
    let dateRepeated = false;

    if (options.datesAll.year && !options.datesAll.month) {
      let isYears = true;
      for (let i = 0; isYears && i < options.dates.length; i++) {
        if (currYear === undefined || options.dates[i].year !== currYear) {
          currYear = options.dates[i].year;
        } else if (options.dates[i].year === currYear) {
          isYears = false;
        }
      }
      return YEAR_MILLISECONDS;
    }

    for (let i = 0; i < options.dates.length; i++) {
      // If data passes through >= 3 years but data is spaced less than a year
      // apart, ensure that ticks are spaced by years.
      if (options.datesAll.year && options.datesAll.month) {
        if (currYear === undefined || options.dates[i].year !== currYear) {
          currYear = options.dates[i].year;
          yearsTraversed += 1;
        } else if (!yearRepeated && options.dates[i].year === currYear) {
          yearRepeated = true;
        }
        if (yearRepeated && yearsTraversed >= 3) {
          return YEAR_MILLISECONDS;
        }
      }
    }

    for (let i = 0; i < options.dates.length; i++) {
      // If data passes through >= 3 months but data is spaced less than a
      // month apart, ensure that ticks are spaced by months.
      if (options.datesAll.month && options.datesAll.date) {
        if (currMonth === undefined || options.dates[i].month !== currMonth) {
          currMonth = options.dates[i].month;
          monthsTraversed += 1;
        } else if (!monthRepeated && options.dates[i].month === currMonth) {
          monthRepeated = true;
        }
        if (monthRepeated && monthsTraversed >= 3) {
          return 1000 * 60 * 60 * 24 * 30;
        }
      }
    }

    for (let i = 0; i < options.dates.length; i++) {
      // If data passes through >= 3 dates but data is spaced less than a day
      // apart, ensure that ticks are spaced by days.
      if (options.datesAll.date) {
        if (currDate === undefined || options.dates[i].date !== currDate) {
          currDate = options.dates[i].date;
          datesTraversed += 1;
        } else if (!dateRepeated && options.dates[i].date === currDate) {
          dateRepeated = true;
        }
        if (dateRepeated && datesTraversed >= 3) {
          return 1000 * 60 * 60 * 24;
        }
      }
    }

    if (options.datesAll.hour && !options.datesAll.year && !options.datesAll.month) {
      const fd = options.dates[0];
      const ld = options.dates[options.dates.length - 1];
      const d1 = new Date();
      const d2 = new Date();
      const hour = 1000 * 60 * 60;
      d1.setUTCHours(fd.hour);
      d1.setUTCMinutes(fd.minute);
      d2.setUTCHours(ld.hour);
      d2.setUTCMinutes(ld.minute);
      if (d2.valueOf() - d1.valueOf() > hour) {
        return hour;
      }
    }
  }
  return null;
}

// A Highcharts `formatter` function to format x-axis dates.
function formatterDate(context, options, interval) {
  // INDEX = (INDEX >= options.series[0].data.length) ? 0 : INDEX;
  // const index = INDEX++;
  const index = getDataIndex(context.value, options.series[0].data, interval);

  // This gives some whack results...
  // const date = new Date(context.value);

  // ...therefore, track the index of this tick:
  // options.xAxisLabelIndex = options.xAxisLabelIndex || 0;
  // options.xAxisLabelIndex %= options.series[0].data.length;
  // const index = options.xAxisLabelIndex;
  // const date = new Date(options.series[0].data[index].x);
  const date = new Date(context.value);
  const momentDate = moment.utc(context.value);

  // options.xAxisLabelIndex += 1;

  options.dateTimeLabelFormat = context.dateTimeLabelFormat;
  const labelFormat = options.dateTimeLabelFormat;
  // if (labelFormat === '%H:%M') labelFormat = '%e. %b'

  // const dateObj = options.dates[index];
  switch (labelFormat) {
    case '%H:%M':
    case '%H:%M:%S':
      var incSecs = false; // context.dateTimeLabelFormat === '%H:%M:%S';
      options.series[0].data[index].xAxisLabel = formatHM(/* date */ momentDate, incSecs);
      return formatHM(/* date */ momentDate, incSecs);
    case '%e. %b':
      options.series[0].data[index].xAxisLabel = [
        MONTHS[date.getUTCMonth()],
        date.getUTCDate(),
      ].join(' ');
      return [MONTHS[date.getUTCMonth()], date.getUTCDate()].join(' ');
    case "%b '%y":
      if (options.dates[index].yearIsProxy) {
        options.series[0].data[index].xAxisLabel = MONTHS[date.getUTCMonth()];
        return MONTHS[date.getUTCMonth()];
      }
      options.series[0].data[index].xAxisLabel = [
        MONTHS[date.getUTCMonth()],
        `’${`${date.getUTCFullYear()}`.substr(2)}`,
      ].join(' ');
      return [MONTHS[date.getUTCMonth()], `’${`${date.getUTCFullYear()}`.substr(2)}`].join(' ');

    case '%Y':
      options.series[0].data[index].xAxisLabel = `${date.getUTCFullYear()}`;
      return `${date.getUTCFullYear()}`;
  }
  options.series[0].data[index].xAxisLabel = '0';
  return '0';
}

function getDataIndex(value, data, interval) {
  let index = 0;
  for (let i = 0; i < data.length; i++) {
    index = i;
    if (value < data[i].x) {
      break;
    }
  }
  return index;
}

function formatHM(date, includeSeconds) {
  // const offset = date.utcOffset() / 60;
  let h = date.hours(); // date.getUTCHours();
  // console.log('in formatHM', date, h, date.utc().hours())
  // h += offset + 4;
  const a = h < 12 ? ' a.m.' : ' p.m.';
  let m = addZeroes(date.minutes() /* date.getUTCMinutes() */, 2);
  let s = includeSeconds ? addZeroes(date.seconds() /* date.getUTCSeconds() */, 2) : null;
  if (h === 12 && m == '00') {
    return 'noon';
  }
  if (h === 0 || h === 12) {
    h = 12;
  } else {
    h %= 12;
  }
  m = m === '00' ? '' : `:${m}`;
  s = includeSeconds ? `:${s}` : '';
  return [h, m, s, a].join('');
}

// Used as Highcharts `formatter` function (not directly).
export function formatterYAxisLabel(
  units,
  value,
  minDecimals,
  special,
  consistent,
  transformUppercase
) {
  value = transformUppercase && typeof value === 'string' ? value.toUpperCase() : `${value}`;
  const decimalUnits = assign({}, units);
  if (units.forceDecimals !== undefined) {
    decimalUnits.forceDecimals = Math.max(units.forceDecimals, minDecimals);
  }
  if (units.maxDecimals !== undefined) {
    decimalUnits.maxDecimals = Math.max(units.maxDecimals, minDecimals);
  }
  value = format.decimals(value, decimalUnits, consistent);
  value = format.commas(value, units.commas);

  const prefix =
    transformUppercase && typeof units.prefix === 'string'
      ? units.prefix.toUpperCase()
      : units.prefix;
  if (special) {
    let neg = '';
    if (prefix && value.charAt(0) === '-') {
      neg = '-';
      value = value.substr(1);
    }
    value = [neg, prefix, value].join('');
  }
  return value;
}

// A Highcharts `formatter` function that returns 'nQ<br/>YYYY'.
// function formatterQuarterlyResults(options) {
//   const rows = options.series[0].data.length;
//   let quarter = undefined;
//   let year = undefined;
//   return function () {
//     let value = '';
//     const parts = this.value.split(/\s/);
//     let tmpQ = parts[0] || null;
//     let tmpY = parts[1] || null;
//     const FY = tmpY ? (tmpY.indexOf('FY') === 0) : false;
//     if (FY) {
//       tmpY = tmpY.substr(2);
//     }
//     if (this.isFirst) {
//       // Sometimes Highcharts runs through the date *twice*!
//       quarter = undefined;
//       year = undefined;
//     }
//     if (tmpQ && rows <= 9/* && options.interactive*/) {
//       value += tmpQ;
//     }
//     if (tmpY) {
//       if (value !== '') {
//         value += '<br>';
//       }
//       if (tmpY !== year) {
//         if (year === undefined) {
//           value += (FY ? 'FY' : '') + tmpY;
//         } else {
//           value += (APOSTROPHE + tmpY.substr(2, 4));
//         }
//       }
//     }
//     quarter = tmpQ;
//     year = tmpY;
//     return value;
//   };
// }

// Returns an array of category objects as an array of strings for use as the
// value for `data[x|yAxis].categories`.
function getCategoryLabels(categories) {
  const strings = [];
  if (categories) {
    categories.forEach((cat, index) => {
      if (typeof cat === 'string' || typeof cat === 'number') {
        strings[index] = cat.toString();
      } else if (cat && typeof cat === 'object') {
        strings[index] = cat.text;
      } else {
        strings[index] = '';
      }
    });
    if (categories.length === 0) strings.push('0');
  }
  return strings;
}

/**
 * Sets min and max y-axis values
 * based on user selections.
 * Applicable for every chart
 * @param {Object} data - data object to pass to Highcharts
 * @param {ChartlosOptions} options - chartlos options
 * @return {Object} modified data object
 */
function writeMinMax(data, options) {
  if (options.axes.minYAxisValue !== undefined) {
    data.yAxis.min = options.axes.minYAxisValue;
  }
  if (options.axes.maxYAxisValue !== undefined) {
    data.yAxis.max = options.axes.maxYAxisValue;
  }
  return data;
}

// Finds if yAxis.min should be set automatically so as to ensure that
// a zero line is included.
function valuesBelowPositive2(options) {
  let found = false;
  for (let s = 0; s < options.series.length; s++) {
    for (let d = 0; d < options.series[s].data.length; d++) {
      if (options.series[s].data[d].y < 0) {
        return false;
      }
      if (!found && options.series[s].data[d].y <= 2) {
        found = true;
      }
    }
  }
  return found;
}

// Finds if yAxis.max should be set automatically so as to ensure that
// a zero line is included.
function valuesAboveNegative2(options) {
  let found = false;
  for (let s = 0; s < options.series.length; s++) {
    for (let d = 0; d < options.series[s].data.length; d++) {
      if (options.series[s].data[d].y > 0) {
        return false;
      }
      if (!found && options.series[s].data[d].y <= -2) {
        found = true;
      }
    }
  }
  return found;
}

// Sometimes decimals are 17 or something outrageous, but decimalsDelta is something like 0.20000000000000002
// which should be just 0.2. This fixes the issue.
function roundPlaces(float) {
  for (var places = 1; places < 19; places++) {
    if (`${float.toFixed(places)}0` === float.toFixed(places + 1)) break;
  }
  return places;
}
